import React, { useEffect, useState } from 'react';

import default_profile_picture from '../default_profile_picture.jpeg';

import { useAuth } from '../AuthComponents';
import { Applicant, getRecruitedApplicants } from '../Api';
import { ApplicantCard } from './ApplicantCard';

export const ProfilePage = () => {
  const auth = useAuth();
  const [recruitedCandidates, setRecruitedCandidates] = useState<Applicant[]>([]);

  useEffect(() => {
    getRecruitedApplicants(auth).then(data => setRecruitedCandidates(data));
    return () => {
      setRecruitedCandidates([]);
    };
  }, [auth.tokens.access_token]);

  return (
    <div>
      <div>
        <div className='card my-3 border-0'>
          <div className='card-body'>
            <img src={default_profile_picture} alt='Profile' className='img-fluid mb-3 rounded' style={{ height: '150px' }} />
            <div className='row'>
              <p className='m-0 p-0'>{auth.user.email}</p>
            </div>
          </div>
          <div className='card-footer border-0 bg-transparent'></div>
        </div>
      </div>
      <h2>Recruited Candidates</h2>
      <div className='row mb-3 px-4'>
        {recruitedCandidates.length > 0 &&
          recruitedCandidates.map((candidate: Applicant, index: number) => (
            <ApplicantCard user={candidate} key={index} recruited={true} />
          ))}
        {recruitedCandidates.length === 0 && (
          <div className='my-5 py-5'>
            <h3>No Candidates Recruited</h3>
            <div>
              <a href='/' className='btn btn-dark'>
                Start Recruiting
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
