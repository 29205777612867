import React, {useEffect, useState}  from 'react';
import './App.css';

import { Link, Route, Routes } from 'react-router-dom';


import AddNewJobComponent from './AddNewJobComponent';
import { getJobCategories } from './Api';
import { AuthStatus, Login, RequireAuth } from './AuthComponents';
import { ApplicantCardsByCategory } from './components/ApplicantCard';
import { ContactPage } from './components/ContactPage';
import { ProfilePage } from './components/ProfilePage';


const HomePage = () => {
  const [jobCategories, setJobCategories] = useState<string[]>([]);

  useEffect(() => {
    getJobCategories().then(data => setJobCategories(data));
    return () => {setJobCategories([])}
  }, []);

  return (
    <div className="text-center mt-3 mt-xl-2">
      <div className='row align-items-center mb-5 pb-3'>
        <div className='col-11 col-md-10 col-lg-7 col-xxl-6 mx-auto px-2'>
          <img src="home.jpeg" alt="Home Page" className="img-fluid rounded" />
        </div>
        <div className='col-12 col-md-8 col-lg-5 mx-auto d-flex align-items-center pb-2 pb-md-0'>
          <div className='px-2'>
          <h1 className='display-2 my-5 f-heading'>{process.env.REACT_APP_AGENCY_NAME}</h1>
          <p className='display-6 f-heading'>Empowering Professionals, Shaping Futures</p>
          <p className='f-text'>Welcome to {process.env.REACT_APP_AGENCY_NAME}, your trusted partner in global talent recruitment.
             We provide recruiters and employers with access to a carefully vetted pool of top-tier
             candidates from around the world, making it easy to find and hire the best professionals for your team.</p>
          <a href='#candidates' className='btn btn-lg btn-custom f-heading mt-4 text-white'>Browse Candidates</a>
        </div>
        </div>
      </div>
      <div id='candidates' className='row my-5 pt-5 pb-2'><div>
        {jobCategories && jobCategories.map((category, index) => (
          <Link className='btn btn-link btn-lg-lg col-5 col-lg-3 m-2 py-3 fs-4 f-heading text-center'
          to='profiles' key={index} state={{category: category}} style={{color:'#271033'}}>{category}</Link>
        ))}
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <div className="App d-flex flex-column" style={{minHeight:'100vh'}}>
      <nav className='navbar navbar-expand-sm'>
        <div className='container-fluid'>
          <a href='/' className='navbar-brand f-heading'>{process.env.REACT_APP_AGENCY_NAME}</a>
          <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarNav'
            aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <div className='navbar-nav me-auto'>
              <Link to='/contact' className='nav-link'>Contact</Link>
            </div>
            <div className='d-flex'>
              <AuthStatus
                menuDisplay={<li className='mx-auto'><a className='dropdown-item f-heading' href='/profile'>Profile</a></li>}
              />
            </div>
          </div>
        </div>
      </nav>
      <div className='container'>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login/>} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/newj" element={<AddNewJobComponent/>} />
          <Route path="/profiles" element={<ApplicantCardsByCategory />} />
          <Route path="/profile" element={<RequireAuth><ProfilePage /></RequireAuth>} />
        </Routes>
      </div>
      <footer className='footer mt-auto'>
        <div className='container'>
          <p className='f-heading'>{process.env.REACT_APP_AGENCY_NAME} &copy; {new Date().getFullYear()}</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
