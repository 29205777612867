import React, { useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';


import { apiAuthProvider } from './routes/auth';

interface AuthContextType {
	user: any;
	tokens: any;
	signin: (email: string, password: string, callback: any) => void;
	signin_google_user: (jwtToken: string, callback: any) => void;
	signout: (callback: any) => void;
	refresh_token: (callback: any) => void;
	signup: (email: string, password: string, callback: any) => void;
	getuser: (access_token: string, callback: any) => void;
	request_password_reset_token: (email: string, callback: (s: number, msg: string) => void) => void;
	reset_password: (token: string, password: string, callback: (s: number, msg: string) => void) => void;
}
export const AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth() {
	return React.useContext(AuthContext);
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
	const [user, setUser] = useState<any>({
		'username': localStorage.getItem('username'),
		'email': localStorage.getItem('email'),
	});
	const [tokens, setTokens] = useState<any>({
		'access_token': localStorage.getItem('access_token'),
		'refresh_token': localStorage.getItem('refresh_token'),
	});

	const signin = (email: string, password: string, callback: (result: any) => void) => {
		/**
		 * callback: returns result_code:
		 * 	200: success
		 * 	400: bad request
		 * 	401: unauthorized
		 */
		return apiAuthProvider.signin(email, password, (data: any) => {
			if (data && typeof data === 'object' && 'user' in data) {
				localStorage.setItem('email', data['user']['email']);
				localStorage.setItem('username', data['user']['username']);
				localStorage.setItem('access_token', data['token']['access_token']);
				localStorage.setItem('refresh_token', data['token']['refresh_token']);
				setUser({
					...user,
					username: data['user']['username'],
					email: data['user']['email']
				});
				setTokens({
					...tokens,
					access_token: data['token']['access_token'],
					refresh_token: data['token']['refresh_token']
				});
				callback(200);
			} else {
				localStorage.clear();
				setUser({...user, username: null, email: null});
				setTokens({...tokens, access_token: null, refresh_token: null});
				callback(data);
			}
		});
	};

	const signin_google_user = (jwtToken: string, callback: any)  => {
		return apiAuthProvider.signInGoogleUser(jwtToken, (data: any) => {
			if (typeof data === 'number') {
				callback(false);
			} else {
				localStorage.setItem('email', data['user']['email']);
				localStorage.setItem('username', data['user']['username']);
				localStorage.setItem('access_token', data['token']['access_token']);
				localStorage.setItem('refresh_token', data['token']['refresh_token']);
				setUser(data['user']);
				setTokens(data['token']);
				callback(true);
			}
		});
	};

	const signout = (callback: any) => {
		let access_token = tokens ? tokens.access_token : null;
		if (!access_token) {
			access_token = localStorage.getItem('access_token');
		}
		return apiAuthProvider.signout(access_token, (result_code: number) => {
			if (result_code === 200) {
				localStorage.clear();
				setUser({...user, username: null, email: null});
				setTokens({...tokens, access_token: null, refresh_token: null});
			}
			callback(result_code);
		});
	};

	const refresh_token = (callback: any) => {
		let access_token = tokens ? tokens.access_token : null;
		let refresh_token = tokens ? tokens.refresh_token : null;
		if (!access_token || !refresh_token) {
			callback(400);
			return;
		}

		return apiAuthProvider.refresh_token(access_token, refresh_token, (data: any) => {
			if (data === 401) {
				localStorage.clear();
				setUser({...user, username: null, email: null});
				setTokens({...tokens, access_token: null, refresh_token: null});
				callback(data);
			} else if (data === 400) {
				callback(data);
			} else if (data && 'access_token' in data) {
				setTokens({
					...tokens,
					access_token: data['access_token'],
					refresh_token: data['refresh_token']
				});
				localStorage.setItem('access_token', data['access_token']);
				localStorage.setItem('refresh_token', data['refresh_token']);
				callback(200);
			}
		});
	};

	const signup = (email: string, password: string, callback: any) => {
		return apiAuthProvider.signup(email, password, (status_code: number) => {
			callback(status_code);
		});
	};

	const getuser = (access_token: string, callback: any) => {
		if (!access_token) {
			callback(400);
			return;
		}

		return apiAuthProvider.getuser(access_token, (data: any) => {
			if (typeof data === 'number' && data === 401) {
				setUser({...user, username: null, email: null});
				setTokens({...tokens, access_token: null, refresh_token: null});
			} else if (typeof data === 'object' && 'username' in data) {
				setUser({
					...user,
					username: data['username'],
					email: data['email']
				});
			}
			callback(data);
		}
	)};

	const request_password_reset_token = (
		email: string,
		callback: (res_status: number, msg: string) => void
	) => {
		return apiAuthProvider.request_password_reset_token(email, (res_status: number, msg: string) => {
			callback(res_status, msg);
		});
	};

	const reset_password = (token: string, password: string, callback: (status_code: number, msg: string) => void) => {
		return apiAuthProvider.reset_password(token, password, (status_code: number, msg: string) => {
			callback(status_code, msg);
		});
	};

	return (<AuthContext.Provider value={
		{user, tokens, signin, signin_google_user, signout, refresh_token, signup, getuser, request_password_reset_token, reset_password}
	}>{children}</AuthContext.Provider>);
}

/**
 *
 * @param props
 * 		onClickM: function to call when the menu is clicked
 * 		menuDisplay: JSX element to display in the dropdown (
 * 			list of <li><a class="dropdown-item" href="#">Action</a></li> items)
 * @returns
 */
export function AuthStatus(props: any) {
	let auth = useAuth();
	let navigate = useNavigate();

	if (!auth.tokens || !auth.tokens.access_token) {
		return (
			<div className='row mx-auto justify-content-center justify-content-md-end'>
				<Link to="/login"
		className={`mx-auto btn btn-outline-${props.theme === 'dark' ? 'light': 'dark'} me-2`} onClick={() => props.onClickM(false)}>Log In</Link>
			</div>
		);
	}

	return (
		<div className="dropdown py-0 mx-auto">
			<button className="btn btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
				<i className="bi bi-person-circle fs-3" onClick={() => {
					if (props.onClickM)
						props.onClickM(200);
				}}></i>
			</button>
			<ul className="dropdown-menu dropdown-menu-end px-0 mx-auto">
				{props.menuDisplay}
				<li className='mx-auto' key="signout">
					<button className="dropdown-item" onClick={() => {
							auth.signout((result_code: number) => {
								if (props.onClickM) {
									props.onClickM(result_code === 200);
								}
								if (result_code === 200)
									navigate("/login");
							});
							}}>sign out&nbsp;
						<i className="bi bi-box-arrow-right fs-6" />
					</button>
				</li>
			</ul>
		</div>
	);
}

/**
 * Context wrapper around a route if it needs to be protected
 *
 * e.g.
 * <Route path="/protected" element={
		<RequireAuth>
			<Suspense fallback={<>...</>}>
				<Sudoku />
			</Suspense>
		</RequireAuth>
	} />
 * @param param0
 * @returns
 */
export function RequireAuth({ children }: { children: JSX.Element }) {
	let auth = useAuth();
	let location = useLocation();

	if (!auth.tokens || !auth.tokens.access_token) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
}

export function Login(props: any) {
	const navigate = useNavigate();
	const location: any = useLocation();
	const auth = useAuth();

	const [displayLoginInputError, setDisplayLoginInputError] = useState<boolean>(false);


	const locationState: any = location.state;
	const from = locationState?.from?.pathname || "/";

	const client_id: any = process.env.REACT_APP_AUTH_CLIENT_ID;

	function loadUser() {
		let access_token = localStorage.getItem('access_token');
		if (access_token === null  || access_token === undefined) {
			return;
		}

		auth.getuser(access_token, (data: any) => {
			if (data === undefined || data === null || (typeof data !== 'object' && data !== 401)) {
				auth.signout((_: number) => {});
				localStorage.clear();
			} else if (typeof data === 'object' && 'username' in data) {
				navigate(from, {replace: true});
			} else if (data === 401) {
				auth.refresh_token((res_status: number) => {
					if (res_status === 200) {
						loadUser();
					} else {
						auth.signout((_: number) => {});
						localStorage.clear();
					}
				});
			} else {
				auth.signout((_: number) => {});
				localStorage.clear();
			}
		});
	}

	const initGoogleLoginAPI = async () => {
		try {
			// @ts-ignore
			await google.accounts.id.initialize({
				client_id: client_id,
				callback: (response: any) => {
					auth.signin_google_user(response.credential, (success: boolean) => {
						if (success)
							navigate(from, { replace: true });
						else
							alert('Error Signing In');
					}
				)},
				// context: 'signin',
			});
			// @ts-ignore
			google.accounts.id.renderButton(
				document.getElementById("signInDiv"), {
					theme: "outline", size: "large"
				}
			);
			// @ts-ignore
			// google.accounts.id.prompt();
		} catch (_) {}
	};

	useEffect(() => {
		if (auth.tokens && auth.tokens.access_token) {
			navigate('/');
		}
		initGoogleLoginAPI();

		return () => {
			// No cleanup required for current implementation of google.accounts.id
		};
	}, []);

	function submitForm(e: any) {
		e.preventDefault();
		let email = e.target.elements.login_email.value;
		let password = e.target.elements.login_password.value;

		auth.signin(email, password, (result_code: number) => {
			// Send them back to the page they tried to visit when they were
			// redirected to the login page. Use { replace: true } so we don't create
			// another entry in the history stack for the login page.  This means that
			// when they get to the protected page and click the back button, they
			// won't end up back on the login page, which is also really nice for the
			// user experience.
			if (result_code === 200) {
				e.target.elements.login_email.value = '';
				e.target.elements.login_password.value = '';
				navigate(from, { replace: true });
			} else if (result_code === 401) {
				setDisplayLoginInputError(true);
			} else if (result_code === 400) {
				alert("Error while logging in user");
				setDisplayLoginInputError(false);
			}
		});
	}

	return (
		<div className='mt-5 pt-5 mx-auto col-10 col-md-8 col-lg-6 col-xl-4'>
			<h3 className='mb-5'>Login</h3>
			{/* <form className='' onSubmit={submitForm}>
				<div className="mb-4">
					<label className='form-label' htmlFor="form_name">Email</label>
					<input className='form-control' id='login_email' name="login_email"
						onInput={() => {setDisplayLoginInputError(false)}}
						type='email' required data-error="Email is required."/>
				</div>
				<div className="mb-4">
					<label className='form-label' htmlFor="form_name">Password</label>
					<input className='form-control' type="password" id='login_password'
						onInput={() => {setDisplayLoginInputError(false)}}
						name="login_password" minLength={8} maxLength={64} required
						autoComplete='current-password' data-error="Password is required." />
				</div>
				{displayLoginInputError && <div className="mb-4 bg-danger">
					<p>Wrong email or password</p>
				</div>}
				<button className={`btn btn-outline-${props.theme === 'dark' ? 'light': 'dark'} col-6 col-md-4 col-lg-3`}
				value='submit' type='submit'>
					Log In
				</button>
			</form>
			<div className='mx-auto mt-3'>
				<Link to='/reset_password' className='text-secondary'>Forgot Password</Link>
			</div> */}
			<div className='mt-5 mb-4 mx-auto'>
				<div id="signInDiv" className='mx-auto d-flex w-50'></div>
			</div>
		</div>
	);
}

export function Register(props: any) {
	const EMAIL_CONFLICT_ERROR_CODE: number = -1;
    const PASSWORD_MISMATCH_ERROR_CODE: number = -2;

    let [email, setEmail] = useState('');
	let [password, setPassword]: any = useState('');
    let [confirmPassword, setConfirmPassword] = useState('');
	let [errorCode, setErrorCode] = useState(0);
	let [accountCreated, setAccountCreated] = useState(false);

	let auth = useAuth();
	let navigate = useNavigate();

	function getErrorMessage(code: number) {
		if (code === EMAIL_CONFLICT_ERROR_CODE)
			return "Username Exists. Please try another one.";
        else if (code === PASSWORD_MISMATCH_ERROR_CODE)
            return "Passwords do not match.";
		return null;
	}

	function submitForm(e: any) {
		e.preventDefault();
        if (password !== confirmPassword) {
            setErrorCode(PASSWORD_MISMATCH_ERROR_CODE);
            return;
        }

		auth.signup(email, password, (result_code: any) => {
			if (result_code === 409) {
				setErrorCode(EMAIL_CONFLICT_ERROR_CODE);
			} else if (result_code === 201) {
				setAccountCreated(true);
			} else {
				alert("Error Registering.")
			}
		});
	}

	useEffect(() => {
		if (auth.tokens && auth.tokens.access_token) {
			navigate('/');
		}

		/* global google */
		// @ts-ignore
		google.accounts.id.renderButton(
			document.getElementById("signInDiv"), {
				theme: "outline", size: "large",
				text: "signup_with"
			}
		);
		// @ts-ignore
		google.accounts.id.prompt();
	}, []);

	return (
		<div className='mt-5 pt-5 mx-auto col-10 col-8 col-lg-6 col-xl-4'>
			<h3 className='mb-5'>Register</h3>
			{!accountCreated &&
			<div>
				{errorCode !== 0 && <div className='forms-error'> <p>{getErrorMessage(errorCode)}</p> </div>}
				<form onSubmit={submitForm} className='mb-5'>
					<div className="mb-4">
						<label className='form-label' htmlFor="form_name">Email</label>
						<input className='form-control' id='login_email' type="email" value={email} onChange={
							e => {
								setEmail(e.target.value);
								if (errorCode === EMAIL_CONFLICT_ERROR_CODE)
									setErrorCode(0);
							}
						}
						required data-error="Email is required."/>
					</div>
					<div className="mb-4">
						<label className='form-label' htmlFor="form_name">Password</label>
						<input className='form-control' type="password" value={password}
							onChange={e => {
								setPassword(e.target.value);
								if (errorCode === PASSWORD_MISMATCH_ERROR_CODE)
									setErrorCode(0);}
							} required data-error="Password is required." />
						{password && <div className='my-3'><input className='form-control' type="password" value={confirmPassword}
							onChange={e => {
								setConfirmPassword(e.target.value);
								if (errorCode === PASSWORD_MISMATCH_ERROR_CODE)
									setErrorCode(0);}
							} required data-error="Password is required." />
							<p>Enter Your Password Again</p>
							</div>}
					</div>
					<button className={`btn btn-outline-${props.theme === 'dark' ? 'light': 'dark'} col-6 col-md-4 col-lg-3`} value='submit'>
						Register
					</button>
				</form>
				<div className='mt-5 mb-4 mx-auto'>
					<div id="signInDiv" className='mx-auto d-flex w-50'></div>
				</div>
			</div>}
			{accountCreated &&
			<div className='mx-auto mt-5 pt-5 w-75'>
				<h3>Email Sent</h3>
				<p>Check Your Email For A Verification Link</p>
			</div>}
			<p className='text-secondary pt-4'>Already Have An Account</p>
			<Link to='/login' className={`btn btn-outline-${props.theme === 'dark' ? 'light': 'dark'} col-6 col-md-4 col-lg-3`}>
				Log In
			</Link>
		</div>
	);
}

export function RequestPasswordResetToken() {
	let auth = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (auth.tokens && auth.tokens.access_token) {
			navigate('/');
		}
	});

	function submitForm(e: any) {
		e.preventDefault();
		let email = document.getElementById('login_email') as HTMLInputElement;
		auth.request_password_reset_token(email.value, (result_code: number, msg: string) => {
			if (result_code === 200) {
				alert('Email Sent');
				navigate('/submit_reset_password');
			} else if (result_code === 400) {
				if (msg === 'email required' || msg === 'user not found') {
					alert(msg);
				} else if (msg === 'error') {
					alert('Error processing request');
				}
			} else if (result_code === 500 && msg === 'error sending email') {
				alert('Error Sending Email. Try Again Later.');
			} else {
				alert('Error Sending Email');
			}
		});
	}

	// center the div below
	return (<div className='mx-auto mt-5 pt-5 w-75'>
		<h3>Reset Password</h3>
		<p>Enter Your Email Below And We Will Send You A Link To Reset Your Password</p>
		<form onSubmit={submitForm}>
			<div className="mb-4">
				<input className='form-control' id='login_email' type="email" required data-error="Email is required."/>
				<div className="form-text">We won't share your email.</div>
			</div>
			<button className='btn btn-primary' value='submit'>Submit</button>
		</form>
	</div>);
}

export function VerifyEmail() {
	const EXPIRED_TOKEN: number = -1;
	const EMAIL_CONFLICT_ERROR_CODE: number = -2;

	let navigate = useNavigate();
	let auth = useAuth();

	let [errorCode, setErrorCode] = useState(0);
	let [token, setToken] = useState('');

	function getErrorMessage(code: number) {
		if (code === EXPIRED_TOKEN)
			return "Token has expired.";
		else if (code === EMAIL_CONFLICT_ERROR_CODE)
			return "Username Exists. Please try another one.";
		return null;
	}

	function submitForm(e: any) {
		e.preventDefault();
		fetch('/api/verify_email', {
			method: 'PUT', headers: { 'Content-Type': 'application/json'},
			body: JSON.stringify({ 'token': token})
		}).then(res => {
			if (!res.ok) {
				if (res.status === 400) {
					res.text().then((e) => {
						if (e === 'invalid token') {
							setErrorCode(EXPIRED_TOKEN);
						} else if (e === 'username exists') {
							setErrorCode(EMAIL_CONFLICT_ERROR_CODE);
						}
					});
				}
				throw new Error("Exception Verifying Email");
			}
			return res.text();
		}).then(data => {
			if (data === 'success') {
				alert('Email Verified');
				navigate('/login');
			}
		}).catch((_) => {});
	}

	useEffect(() => {
		if (auth.tokens && auth.tokens.access_token) {
			navigate('/');
		}
	});

	return (
		<div className='mx-auto mt-5 pt-5 w-75'>
			<h3>Verify Email</h3>
			<p>Check Your Email For A Link To Verify Your Email</p>
			{errorCode !== 0 && <div className='bg-danger'> <p>{getErrorMessage(errorCode)}</p> </div>}
			<form onSubmit={submitForm} className='mb-5'>
				<div className="mb-4">
					<label className='form-label' htmlFor="form_name">Token From Email</label>
					<input className='form-control' id='token' type="string" value={token} onChange={
						e => {setToken(e.target.value);}
					}
					required data-error="Email is required."/>
				</div>
				<button className='btn btn-primary' value='submit'>Submit</button>
			</form>
		</div>
	);
}

export function ResetPassword() {
	let auth = useAuth();
	let navigate = useNavigate();

	const EXPIRED_TOKEN: number = -1;
    const PASSWORD_MISMATCH_ERROR_CODE: number = -2;
	const OLD_PASSWORD_ERROR_CODE: number = -3;

    let [token, setToken] = useState('');
	let [password, setPassword]: any = useState('');
    let [confirmPassword, setConfirmPassword] = useState('');
	let [errorCode, setErrorCode] = useState(0);

	function getErrorMessage(code: number) {
		if (code === PASSWORD_MISMATCH_ERROR_CODE)
            return "Passwords do not match.";
		else if (code === EXPIRED_TOKEN)
			return "Token has expired.";
		else if (code === OLD_PASSWORD_ERROR_CODE)
			return "new password cannot be the same as the old password";
		return null;
	}

	async function submitForm(e: any) {
		e.preventDefault();
        if (password !== confirmPassword) {
            setErrorCode(PASSWORD_MISMATCH_ERROR_CODE);
            return;
        }

		auth.reset_password(token, password, (result_code: number, msg: string) => {
			if (result_code === 200) {
				alert('Password Reset');
				navigate('/login');
			} else if (result_code === 400) {
				if (msg === 'invalid token') {
					setErrorCode(EXPIRED_TOKEN);
				} else if (msg === 'new password cannot be the same as the old password') {
					setErrorCode(OLD_PASSWORD_ERROR_CODE);
				}
			} else {
				alert('Error Resetting Password');
			}
		});
	}

	useEffect(() => {
		if (auth.tokens && auth.tokens.access_token) {
			navigate('/');
		}
	});

	return (
		<div className='mx-auto mt-5 pt-5 w-75'>
			<h3>Verify Password Reset</h3>
			<p>Check Your Email For A Link To Reset Your Password</p>
			{errorCode !== 0 && <div className='bg-danger'> <p>{getErrorMessage(errorCode)}</p> </div>}
			<form onSubmit={submitForm} className='mb-5'>
				<div className="mb-4">
					<label className='form-label' htmlFor="form_name">Token From Email</label>
					<input className='form-control' id='token' type="string" value={token} onChange={
						e => {setToken(e.target.value);}
					}
					required data-error="Email is required."/>
					<div className="form-text">We won't share your email.</div>
				</div>
				<div className="mb-4">
					<label className='form-label' htmlFor="form_name">Password</label>
					<input className='form-control' type="password" value={password}
                        onChange={e => {
                            setPassword(e.target.value);
                            if (errorCode === PASSWORD_MISMATCH_ERROR_CODE)
                                setErrorCode(0);}
                        } required data-error="Password is required." />
                    {password && <div className='my-3'><input className='form-control' type="password" value={confirmPassword}
                        onChange={e => {
                            setConfirmPassword(e.target.value);
                            if (errorCode === PASSWORD_MISMATCH_ERROR_CODE)
                                setErrorCode(0);}
                        } required data-error="Password is required." />
                        <p>Enter Your Password Again</p>
                        </div>}
				</div>
				<button className='btn btn-primary' value='submit'>Submit</button>
			</form>
		</div>
	);
}
