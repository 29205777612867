export interface Applicant {
  id: number;
  name: string;
  job_id: number;
  experience_summary: string|null;
};

export const getApplicantsByCategory = async (category: string|null): Promise<Applicant[]> => {
  if (!category) { return []; }

  const res = await fetch(`${process.env.REACT_APP_API_URL}/jobs/candidates?category=${category}`);
  if (!res.ok) {
    return [];
  }
  return res.json();
};

export const getJobCategories = async (): Promise<string[]> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/jobs/categories`);
  if (!res.ok) {
    return [];
  }
  return res.json();
};

export const getRecruitedApplicants = async (auth: any): Promise<Applicant[]> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/jobs/recruits`, {
    headers: {
      'Authorization': `Bearer ${auth.tokens.access_token}`
    }
  });

  if (!res.ok) {
    if (res.status === 401) {
      auth.refresh_token((code: number) => {
        if (code === 200) {
          return getRecruitedApplicants(auth);
        }
      });
    }
    return [];
  } else {
    return res.json();
  }
};

export const getRecruitIds = async (auth: any): Promise<number[]> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/jobs/recruitsids`, {
    headers: {
      'Authorization': `Bearer ${auth.tokens.access_token}`
    }
  });
  if (!res.ok) {
    if (res.status === 401) {
      auth.refresh_token((code: number) => {
        if (code === 200) {
          return getRecruitIds(auth);
        }
      });
    }
    return [];
  } else {
    return res.json();
  }
};

export const recruitApplicant = async (auth: any, data: FormData): Promise<boolean|string> => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/jobs/recruit`, {
    method: 'POST',
    body: data,
    headers: {
      'Authorization': `Bearer ${auth.tokens.access_token}`
    }
  });

  if (!res.ok) {
    if (res.status === 401) {
      auth.refresh_token((code: number) => {
        if (code === 200) {
          return recruitApplicant(auth, data);
        } else {
          return false;
        }
      });
    } else if (res.status === 400) {
      return res.json();
    }
    return res.ok;
  } else {
    return res.ok;
  }
};
