
import React, { useEffect, useState}  from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '../AuthComponents';
import { Applicant, getApplicantsByCategory, getJobCategories, getRecruitIds, recruitApplicant } from '../Api';

const SAMPLE_BIOS = [
  'Daniel is a highly skilled full-stack developer who has led the development of several successful web applications. He excels in creating efficient, secure, and user-friendly platforms and is passionate about leveraging cloud technology to solve complex problems.',
  'Sarah is a talented software engineer with a passion for creating innovative solutions to complex problems. She has experience working with a variety of programming languages and is always eager to learn new technologies.',
  'John is a skilled data scientist with a background in mathematics and computer science. He has experience working with large datasets and developing machine learning models to extract valuable insights from data.',
  'Emily is a dedicated software developer with a strong background in computer science. She has experience working on a wide range of projects and is always looking for new challenges to tackle.',
  'Michael is a talented web developer with a passion for creating beautiful and functional websites. He has experience working with a variety of technologies and is always eager to learn new skills.'
];

const SAMPLE_SKILLS = [
  'Java, Python, Full-Stack Development, Cloud Computing',
  'JavaScript, React, Node.js, MongoDB',
  'SQL, Data Analysis, Machine Learning, Python',
  'HTML, CSS, JavaScript, Web Design',
  'Java, Spring Boot, Microservices, Docker'
];

export interface ApplicantCardProps {
  user: Applicant;
  recruited: boolean;
};

interface ApplicantFormData {
  name: string;
  email: string;
  phone: string;
  message: string;
  jobDescription: string;
};

export const ApplicantCard = (props: ApplicantCardProps) => {
  const auth = useAuth();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useState<ApplicantFormData>({
    name: '',
    email: (auth.user && auth.user.email) ? auth.user.email : '',
    phone: '',
    message: '',
    jobDescription: ''
  });
  const [submittedMessage, setSubmittedMessage] = useState<string>(`Your message has been sent to ${props.user.name}`);

  const clearForm = () => {
    setFormData({
      name: '',
      email: (auth.user && auth.user.email) ? auth.user.email : '',
      phone: '',
      message: '',
      jobDescription: ''
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (value.length > 1000 && name !== 'message')
      return;
    else if (value.length > 2000 && name === 'message')
      return;

    setFormData({ ...formData, [name]: value });
  }

  return (
    <div className='col-md-6 col-xl-4 my-3'>
      <div className='card my-3' style={{backgroundColor: '#613f73'}}>
        <div className='card-body'>
          <p className='card-title f-text fw-bold text-white'>{props.user.name}</p>
          <div className='row align-items-center'>
            <div className='col-5'><img src={`passportpics/${Math.floor(Math.random() * 6) + 1}.jpeg`} alt='Profile' className='img-fluid mb-3 rounded'/></div>
            <div className='col-7 py-2 text-white'>
              <div className='mb-2 rounded' style={{'backgroundColor': '#68457B'}}>
                <p className='fw-light py-0 my-0' style={{fontSize: '.8em'}}><small>Skills</small></p>
                <p className='py-0 my-0 fw-bold' style={{fontSize: '.7em'}}>{SAMPLE_SKILLS[Math.floor(Math.random() * SAMPLE_SKILLS.length)]}</p>
              </div>
              <div className='mt-2 rounded' style={{'backgroundColor': '#68457B'}}>
                <p className='fw-light py-0 my-0' style={{fontSize: '.7em'}}><small>Experience</small></p>
                <p className='py-0 my-0 fw-bold' style={{fontSize: '.8em'}}>5 Years</p>
              </div>
            </div>
          </div>
          {/* <p className='card-text' style={{ fontSize: '.9em' }}>{props.user.experience_summary}</p> */}
          <p className='card-text text-white mt-3' style={{ fontSize: '.75em' }}>{SAMPLE_BIOS[Math.floor(Math.random() * SAMPLE_BIOS.length)]}</p>
        </div>
        <div className='card-footer border-0 bg-transparent'>
          <button className='btn mbg-primary col-5 fw-bold me-1 text-white' onClick={() => {
            window.open(`${process.env.REACT_APP_API_URL}/resume?id=${props.user.id}&job=${props.user.job_id}`, '_blank');
          }} style={{fontSize: '.8em'}}>Resume</button>
          {(props.recruited === undefined || !props.recruited) && <button className='btn btn-dark col-5 fw-bold ms-1' data-bs-toggle="modal"
            data-bs-target={(auth.user && auth.user.username) ? `#applicationModal${props.user.id}` : `#loginModal${props.user.id}`}
            style={{fontSize: '.8em'}}>
            Recruit {props.user.name.split(" ")[0]}
          </button>}
          {(props.recruited !== undefined && props.recruited) && <button className='btn btn-dark col-5 fw-bold ms-1' data-bs-toggle="modal"
            data-bs-target={(auth.user && auth.user.username) ? `#applicationModal${props.user.id}` : `#loginModal${props.user.id}`}
            disabled style={{fontSize: '.8em'}}>
            Contacted
          </button>}
        </div>
      </div>
      <div className='modal' id={`loginModal${props.user.id}`} tabIndex={-1} aria-labelledby="loginModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Login Required</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Login to recruit {props.user.name}</p>
              <a href='/login' className='btn btn-primary'>Login</a>
            </div>
          </div>
        </div>
      </div>
      <div className='modal' id={`applicationModal${props.user.id}`} tabIndex={-1} aria-labelledby="applicationModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Recruit {props.user.name}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {!formSubmitted &&
            <div className="modal-body">
              <p>Contact Us To Initiate the Process Of Recruiting {props.user.name}</p>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const data = new FormData();
                  data.append('name', formData.name);
                  data.append('email', auth.user.email);
                  data.append('phone', formData.phone);
                  data.append('message', formData.message);
                  data.append('jobDescription', formData.jobDescription);
                  data.append('candidate_id', String(props.user.id));

                  recruitApplicant(auth, data).then((res: string|boolean) => {
                    if (typeof res === 'string' && res === 'recruitment record already exists') {
                      setSubmittedMessage('You have already initiated the recruitment process for this candidate');
                      setFormSubmitted(true);
                      document.getElementById(`applicationModal${props.user.id}`)?.addEventListener('hide.bs.modal', () => {
                        setFormSubmitted(false);
                        clearForm();
                        setSubmittedMessage(`Your message has been sent to ${props.user.name}`);
                      });
                    } else if (!res) {
                      alert('An error occurred. Please try again later');
                    } else {
                      setFormSubmitted(true);
                      document.getElementById(`applicationModal${props.user.id}`)?.addEventListener('hide.bs.modal', () => {
                        setFormSubmitted(false);
                        clearForm();
                        setSubmittedMessage(`Your message has been sent to ${props.user.name}`);
                      });
                    }
                  });
                }}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Your Name</label>
                  <input type="text" className="form-control" name="name" aria-describedby="nameHelp"
                    onChange={(e: any) => { handleInputChange(e); }} />
                  <div id="nameHelp" className="form-text">We'll never share your name with anyone else.</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email address</label>
                  <input type="email" className="form-control" name="email" aria-describedby="emailHelp"
                    value={(auth && auth.user && auth.user.email) ? auth.user.email: ''} readOnly
                  />
                  <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">Phone Number</label>
                  <input type="tel" className="form-control" name="phone" aria-describedby="phoneHelp"
                    onChange={(e: any) => { handleInputChange(e); }} />
                  <div id="phoneHelp" className="form-text">We'll never share your phone number with anyone else.</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">Message</label>
                  <textarea className="form-control" name="message" rows={3}
                    onChange={(e: any) => { handleInputChange(e); }}
                  ></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="jobDescription" className="form-label">Job Description</label>
                  <input type="text" className="form-control" name="jobDescription" aria-describedby="jobDescriptionHelp"
                    onChange={(e: any) => { handleInputChange(e); }} />
                  <div id="jobDescriptionHelp" className="form-text">You can upload a link to a job description here.</div>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>}
            {formSubmitted &&
            <div className="modal-footer">
              <div className='mx-auto'>{submittedMessage}</div>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ApplicantCardsByCategory = () => {
  const auth = useAuth();

  const location = useLocation();
  const category = location.state ? location.state.category : null;

  const [applicants, setCandidateProfiles] = useState<Applicant[]>([]);
  const [currentCategory, setCurrentCategory] = useState<string|null>(null);
  const [jobCategories, setJobCategories] = useState<string[]>([]);
  const [recruitedCandidatesIds, setRecruitedCandidatesIds] = useState<number[]>([]);
  const [selectedRecruitedStatus, setSelectedRecruitedStatus] = useState<string>('all');

  useEffect(() => {
    getJobCategories().then(data => {
      setJobCategories(data);
      setCurrentCategory(category ? category : data.length > 0 ? data[0]: null);
    });
    return () => {setJobCategories([])}
  }, []);

  useEffect(() => {
    getRecruitIds(auth).then(data => setRecruitedCandidatesIds(data));
    return () => {setRecruitedCandidatesIds([])}
  }, []);

  useEffect(() => {
    getApplicantsByCategory(currentCategory).then(data => setCandidateProfiles(data));

    return () => {
      setCandidateProfiles([]);
    }
  }, [currentCategory, category]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRecruitedStatus(e.target.value);
  }

  return (
    <div>
      <div className='d-flex gap-3 overflow-x-scroll py-2'>
        {jobCategories.map((c, index) => (
          <button className={`btn btn-${c === currentCategory ? 'light text-dark': 'custom text-white'} f-heading`} key={index}
          onClick={() => setCurrentCategory(c)}>{c}</button>
        ))}
      </div>
      <h1 className='f-heading my-2 text-center'>{currentCategory}</h1>
      <div className='mb-3 px-lg-4'>
        {applicants.length > 0 &&
        <div className='row align-items-center justify-content-center'>
          <div className='col col-lg-6'>
            <select name='recruitedCandidates' id='recruitedCandidates' className='form-select' onChange={handleFilterChange}>
              <option className='f-text' value='all'>All</option>
              <option className='f-text' value='notRecruited'>Not Recruited</option>
              <option className='f-text' value='recruited'>Contacted</option>
            </select>
          </div>
          <div className='row align-items-center justify-content-center'>
          {applicants.map((user: Applicant, index: number) => {
            if ((selectedRecruitedStatus === 'recruited' && !recruitedCandidatesIds.includes(user.id)) ||
              (selectedRecruitedStatus === 'notRecruited' && recruitedCandidatesIds.includes(user.id))) {
              return null;
            }
            return (
              <ApplicantCard user={user} key={index} recruited = {
                recruitedCandidatesIds.includes(user.id) ? true: false
              }/>
            );
          })}
          </div>
        </div>}
        {applicants.length === 0 &&
        <div className='my-5 py-5'>
          <p className='h-2 f-heading'>There are currently no applicants for {currentCategory} roles</p>
        </div>}
      </div>
    </div>
  );
};
