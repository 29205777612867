import React from 'react';

export const ContactPage: React.FC = () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8 col-sm-10">
          <div className="card shadow-sm">
            <div className="card-body text-center">
              <h3 className="card-title">Contact Us</h3>
              <p className="card-text mt-3">
                For any inquiries, please reach out to us via email at:
              </p>
              <a className='btn btn-dark' href={`mailto:${process.env.REACT_APP_AGENCY_CONTACT}`}>
                {process.env.REACT_APP_AGENCY_CONTACT}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
